import { Link, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import parse from "html-react-parser";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
import ContactFormHome from "../components/contact-form-home";
import Layout from "../components/layout";
import { IoIosArrowForward } from "react-icons/io";
import { Button } from "react-bootstrap";

import BestDealIcon from "../images/best-deal.svg";
import ExpertIcon from "../images/expert.svg";
import FlexibleIcon from "../images/flexible.svg";
import ExpertCard from "../components/expert-card";

const BlogPostTemplate = ({
	data: { post, site, otherPosts, blogImg, andrew, matt, tony },
}) => {
	const authors = {
		"Tony Beck": {
			image: tony?.localFile.childImageSharp.gatsbyImageData,
			altText: tony?.altText,
		},
		"Andrew Hogan": {
			image: andrew?.localFile.childImageSharp.gatsbyImageData,
			altText: andrew?.altText,
		},
		"Matt Fielden": {
			image: matt?.localFile.childImageSharp.gatsbyImageData,
			altText: matt?.altText,
		},
		Guest: {
			altText: "Guest writer",
		},
	};
	const siteUrl = site.siteMetadata.siteUrl;
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Resources",
				item: {
					url: `${siteUrl}/resources`,
					id: `${siteUrl}/resources`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: `${post.title}`,
				item: {
					url: `${siteUrl}/resources/${post.slug}`,
					id: `${siteUrl}/resources/${post.slug}`,
				},
			},
		],
	};
	function stripHtml(html) {
		if (typeof window !== "undefined" && window.document) {
			// Create a new div element
			var temporalDivElement = document.createElement("div");
			// Set the HTML content with the provided HTML string
			temporalDivElement.innerHTML = html;
			// Retrieve the text property of the element (which will strip away the HTML)
			return (
				temporalDivElement.textContent || temporalDivElement.innerText || ""
			);
		} else {
			// If window is not defined, return the input string unchanged or handle it appropriately
			return html;
		}
	}

	let plainTextExcerpt = stripHtml(post.excerpt);

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={`${post.title} | Flex Office Solutions`}
				description={plainTextExcerpt}
				language="en"
				openGraph={{
					type: "article",
					url: `${siteUrl}/resources/${post.slug}`,
					title: `${post.title} | Flex Office Solutions`,
					description: plainTextExcerpt,
					images: [
						{
							url: `${siteUrl}${post.blogFields.featuredImage.publicUrl}`,
							width: `${post.blogFields.featuredImage.localFile.childImageSharp.original.width}`,
							height: `${post.blogFields.featuredImage.localFile.childImageSharp.original.height}`,
							alt: `${post.blogFields.featuredImage.altText}`,
						},
					],
				}}
			/>

			<section className="bg-primary bg-opacity-10 pb-2 pt-4 pt-lg-5 mb-xl-3 mb-2">
				<Container>
					<Row>
						<Col className="text-center">
							<p className="text-med-grey fw-bold">
								{post.blogFields.smallHeading}
							</p>
							<h1>{post.title}</h1>
							<p className=" fw-bold">{post.blogFields.minsToRead} min read</p>
						</Col>
					</Row>
				</Container>
				<Container>
					<Row className="g-xl-6 g-4 h-100 justify-content-center">
						<Col lg={10} xl={8}>
							<div className="d-flex justify-content-center align-items-center">
								<ul className="list-unstyled text-center">
									{post.tags.nodes.map((tag) => (
										<Link to={`/resources/tags/${tag.slug}`}>
											<li
												key={tag.id}
												className="d-inline-block py-1 px-3 me-3 mb-2 fw-light text-white border border-1 border-white bg-dark-blue rounded-pill"
											>
												{tag.name}
											</li>
										</Link>
									))}
								</ul>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
			{/* comment */}

			<section className="position-relative ">
				<Container className="  ">
					<Row className="justify-content-center">
						<Col lg={10} xl={8}>
							<nav
								style={{ borderBottom: "1px solid #0B498230" }}
								aria-label="breadcrumb"
								className="pb-2 mb-0 mt-3"
							>
								<ol
									className="breadcrumb pb-0 mb-0"
									style={{ display: "flex", listStyle: "none", padding: 0 }}
								>
									<li className="breadcrumb-item">
										<Link className="black-link" to="/resources">
											Resources
										</Link>
									</li>
									<li
										className="mx-2 text-primary"
										style={{ padding: "0 5px" }}
									>
										<IoIosArrowForward />
									</li>
									<li className="breadcrumb-item " aria-current="page">
										{post.title}
									</li>
								</ol>
							</nav>
							<div className="blog-content pt-3 ">{parse(post.content)}</div>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="bg-dark-blue py-5 mt-4 mt-lg-5">
				<Container>
					<Row className="justify-content-center">
						<Col lg={10} xl={8} className="text-center text-white">
							<h2>What Flex Office Solutions can help with</h2>
							<p>
								Is your business in need of a flexible workspace that grows with
								you? Flex Office Solutions helps businesses of all kinds find
								and secure scalable office solutions. From private offices to
								self-contained floors or simply dedicated desks, we provide
								flexible agreements with transparent pricing to meet your
								needs. Let our dedicated team help you find your next perfect
								office space, so you can maintain your focus on what matters
								most, your business.
							</p>
						</Col>
					</Row>
					<Row className="mb-5  mt-0 g-4 ">
						<Col style={{ minHeight: "100%" }} lg={4}>
							<div
								className="p-4 text-white h-100"
								style={{ border: "2px solid #FFFFFF1A", borderRadius: "24px" }}
							>
								<img
									className="mb-3"
									src={BestDealIcon}
									style={{
										maxWidth: "50px",
									}}
								/>
								<h4 className="fs-5">Guaranteed best deals</h4>
								<p>
									No more endless searches. We negotiate exclusive rates, saving
									you time and money.
								</p>
							</div>
						</Col>
						<Col style={{ minHeight: "100%" }} lg={4}>
							<div
								className="p-4 text-white h-100"
								style={{ border: "2px solid #FFFFFF1A", borderRadius: "24px" }}
							>
								<img
									className="mb-3"
									src={ExpertIcon}
									style={{
										maxWidth: "50px",
									}}
								/>
								<h4 className="fs-5">Free expert guidance</h4>
								<p>
									Our experienced team simplifies your office search with a
									tailored end-to-end service, delivering workspaces in your
									desired location, that fit your brand and empower your
									people. 
								</p>
							</div>
						</Col>
						<Col style={{ minHeight: "100%" }} lg={4}>
							<div
								className="p-4 text-white h-100"
								style={{ border: "2px solid #FFFFFF1A", borderRadius: "24px" }}
							>
								<img
									className="mb-3"
									src={FlexibleIcon}
									style={{
										maxWidth: "50px",
									}}
								/>
								<h4 className="fs-5">Flexible viewings</h4>
								<p>
									Schedule tours at your convenience with our team available to
									join in-person and answer any questions.
								</p>
							</div>
						</Col>
					</Row>
					<Row>
						<Col className="text-center">
							<Button
								as={Link}
								to="/contact-us"
								className={`text-white btn btn-primary px-5 rounded-pill w-100 w-md-50 w-lg-auto fw-bold `}
							>
								GET A QUOTE
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-4">
				<Container>
					<Row className="justify-content-around pt-5">
						<Col lg={10} xl={8} className="d-md-flex justify-content-center">
							{["Matt Fielden", "Tony Beck", "Andrew Hogan"].includes(
								post.author.node.name
							) && (
								<div className="text-center text-md-start">
									<GatsbyImage
										image={authors[post.author.node.name]?.image}
										className="rounded-circle  mx-auto mb-4 greyImg"
										style={{
											maxHeight: "150px",
											maxWidth: "150px",
											minHeight: "150px",
											minWidth: "150px",
										}}
										alt={authors[post.author.node.name]?.altText}
									/>
								</div>
							)}
							<div className="ps-md-4 px-4 pe-md-0 text-center text-md-start">
								<h3 className="  ">{post.author.node.name}</h3>
								<p className="  ">{post.author.node.description}</p>
								{/* <Button
											as={Link}
											to="/contact-us"
											style={{ borderRadius: "200px" }}
											variant="primary"
											className="text-white"
										>
											Get in touch
										</Button> */}
							</div>
						</Col>
					</Row>
				</Container>
			</section>
			<section>
				<Container className="bg-white my-lg-6 my-3 my-md-5">
					<Row className="justify-content-center">
						<Col lg={10} xl={8}>
							<div
								style={{
									boxShadow: "0px 1px 20px #00000029",
									borderRadius: "12px",
								}}
								className="py-3 py-md-5 px-3 px-md-5  mb-3 bg-white"
							>
								<Row>
									<Col>
										<h3>Get in touch</h3>
									</Col>
								</Row>
								<Row>
									<Col lg={6} xl={5}>
										<p>
											Fill in your contact details below and we’ll put you in
											touch with one of our experts
										</p>
									</Col>
								</Row>
								<ContactFormHome />
							</div>
							<ExpertCard />
						</Col>
					</Row>
				</Container>
			</section>
		</Layout>
	);
};

export default BlogPostTemplate;

export const pageQuery = graphql`
	query PostById($id: String!) {
		post: wpPost(id: { eq: $id }) {
			id
			excerpt
			slug
			content
			author {
				node {
					name
					description
					avatar {
						url
					}
				}
			}
			title
			tags {
				nodes {
					name
					slug
				}
			}
			blogFields {
				minsToRead
				smallHeading
				featuredImage {
					altText
					publicUrl
					localFile {
						publicURL
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 100
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
			}
		}
		matt: wpMediaItem(title: { eq: "Matt New" }) {
			altText
			localFile {
				childImageSharp {
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 100
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
		andrew: wpMediaItem(title: { eq: "Andrew New" }) {
			altText
			localFile {
				childImageSharp {
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 100
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
		tony: wpMediaItem(title: { eq: "Tony" }) {
			altText
			localFile {
				childImageSharp {
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 100
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
